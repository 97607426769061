<template>
  <data-list-view-wrapper>
    <campaign-sidebar
      v-if="$gate.can(['create_campaigns', 'edit_campaigns'])"
      :active="addNewDataSidebar"
      :data="sidebarData"
      @close="toggleDataSidebar"
    />

    <vx-table
      ref="table"
      v-model="selected"
      pagination
      :max-items="itemsPerPage"
      search
      :data="campaigns"
      @selected="openData"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <div class="flex flex-wrap-reverse items-center">
          <button-create
            v-if="$gate.can('create_campaigns')"
            title="Add campaign"
            @click="addNewData"
          />
        </div>

        <items-per-page
          :page="currentPage"
          :per-page="itemsPerPage"
          :total="campaigns.length"
          :queried-items="queriedItems"
          @change="changeItemsPerPage"
        />
      </div>

      <div
        slot="filters"
        class="
          mt-5
          flex flex-wrap-reverse
          items-center
          flex-grow
          justify-between
        "
      >
        <vx-card
          ref="filterCard"
          title="Filters"
          class="mt-6 mb-6"
          collapse-action
        >
          <div class="vx-row flex mt-4">
            <div class="vx-col lg:w-1/6 md:w-1/3 sm:w-1/2 w-full">
              <vs-label text="Region" />
              <v-select
                v-model="filters.region"
                :clearable="false"
                :options="filterRegionList"
                class="bg-white w-full cursor-pointer"
                @input="filter"
              />
            </div>

            <div class="vx-col lg:w-1/6 md:w-1/3 sm:w-1/2 w-full">
              <vs-label text="Country" />
              <v-select
                v-model="filters.country"
                :clearable="false"
                :options="filterCountryList"
                class="bg-white w-full cursor-pointer"
                @input="filter"
              />
            </div>

            <div class="vx-col lg:w-1/6 md:w-1/3 sm:w-1/2 w-full">
              <vs-label text="Status" />
              <v-select
                v-model="filters.status"
                :clearable="false"
                :options="filterStatusList"
                class="bg-white w-full cursor-pointer"
                @input="filter"
              />
            </div>

            <div class="vx-col lg:w-1/6 md:w-1/3 sm:w-1/2 w-full">
              <vs-label text="Start date" />
              <div class="relative">
                <datepicker
                  v-model="filters.startDate"
                  placeholder="Select Date"
                  class="w-full"
                  @input="filter"
                />
                <feather-icon
                  icon="XIcon"
                  svg-classes="w-5 h-5 hover:text-danger stroke-current"
                  class="datepicker-reset"
                  @click="resetDate('startDate')"
                />
              </div>
            </div>

            <div class="vx-col lg:w-1/6 md:w-1/3 sm:w-1/2 w-full">
              <vs-label text="End date" />
              <div class="relative">
                <datepicker
                  v-model="filters.endDate"
                  placeholder="Select Date"
                  class="w-full"
                  @input="filter"
                />
                <feather-icon
                  icon="XIcon"
                  svg-classes="w-5 h-5 hover:text-danger stroke-current"
                  class="datepicker-reset"
                  @click="resetDate('endDate')"
                />
              </div>
            </div>
          </div>
        </vx-card>
      </div>

      <template slot="thead">
        <vs-th sort-key="name">Campaign</vs-th>
        <vs-th sort-key="retailer_name">Retailer</vs-th>
        <vs-th sort-key="description">Description</vs-th>
        <vs-th sort-key="start_date">Start date</vs-th>
        <vs-th sort-key="end_date">End date</vs-th>
        <vs-th sort-key="status">Status</vs-th>
        <vs-th>Action</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr
            v-for="(tr, indextr) in data"
            :key="indextr"
            :data="tr"
            class="clickable-row"
          >
            <vs-td>
              <p class="product-name">{{ tr.name }}</p>
            </vs-td>

            <vs-td>
              <p class="product-name">{{ tr.retailer_name }}</p>
            </vs-td>

            <vs-td>
              <p class="product-description">{{ tr.description }}</p>
            </vs-td>

            <vs-td>{{ tr.start_date }}</vs-td>

            <vs-td>{{ tr.end_date }}</vs-td>

            <vs-td>
              <vs-chip :color="statusColor(tr.status)" class="product-status">
                {{ tr.status | title }}
              </vs-chip>
            </vs-td>

            <vs-td width="120" class="whitespace-no-wrap">
              <feather-icon
                v-if="ableToEditCampaign(tr.user_id)"
                icon="EditIcon"
                svg-classes="w-5 h-5 hover:text-primary stroke-current"
                class="ml-2"
                @click.stop="editData(tr)"
              />
              <feather-icon
                v-if="ableToDeleteCampaign(tr.user_id)"
                icon="TrashIcon"
                svg-classes="w-5 h-5 hover:text-danger stroke-current"
                class="ml-2"
                @click.stop="confirmDelete(tr.id, tr.user_id, tr.name)"
              />
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vx-table>
  </data-list-view-wrapper>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select';

import { CampaignAction, RetailerAction } from '@/store/actionTypes';
import { campaignStatusList, getStatusColor } from '@/enums/CampaignStatusEnum';
import { countryList } from '@/enums/CountryEnum';
import { regionList } from '@/enums/RegionEnum';

import dataListMixin from '@/mixins/dataListMixin';
import manageCampaignMixin from '@/mixins/manageCampaignMixin';
import moduleCampaign from '@/store/campaigns/moduleCampaign';
import moduleRetailer from '@/store/retailers/moduleRetailer';

import ButtonCreate from '@/views/components/buttons/ButtonCreate';
import CampaignSidebar from '@/views/sidebars/CampaignSidebar';
import DataListViewWrapper from '@/views/wrappers/DataListViewWrapper';
import ItemsPerPage from '@/views/components/ItemsPerPage';
import VxTable from '@/components/vx-table/vxTable';

export default {
  components: {
    ButtonCreate,
    CampaignSidebar,
    Datepicker,
    DataListViewWrapper,
    ItemsPerPage,
    vSelect,
    VxTable,
  },
  mixins: [dataListMixin, manageCampaignMixin],
  data() {
    return {
      // Filters
      filters: {
        region: { label: 'All', value: null },
        country: { label: 'All', value: null },
        status: { label: 'All', value: null },
        startDate: null,
        endDate: null,
      },
    };
  },
  computed: {
    ...mapState('campaign', {
      campaigns: ({ campaigns }) => campaigns,
    }),

    statusColor() {
      return (val) => getStatusColor(val);
    },
    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.campaigns.length;
    },
    filterRegionList() {
      const all = { label: 'All', value: null };
      const newList = [...regionList];

      newList.unshift(all);

      return newList;
    },
    filterCountryList() {
      const all = { label: 'All', value: null };
      const newList = [...countryList];

      newList.unshift(all);

      return newList;
    },
    filterStatusList() {
      const all = { label: 'All', value: null };
      const newList = [...campaignStatusList];

      newList.unshift(all);

      return newList;
    },
  },
  methods: {
    ...mapActions('campaign', [CampaignAction.fetch, CampaignAction.remove]),
    ...mapActions('retailer', [RetailerAction.fetch]),

    registerStoreModules() {
      if (!moduleCampaign.isRegistered) {
        this.$store.registerModule('campaign', moduleCampaign);
        moduleCampaign.isRegistered = true;
      }

      if (!moduleRetailer.isRegistered) {
        this.$store.registerModule('retailer', moduleRetailer);
        moduleRetailer.isRegistered = true;
      }
    },
    addNewData() {
      if (!this.$gate.can('create_campaigns')) {
        return;
      }

      this.sidebarData = {};
      this.toggleDataSidebar(true);
    },
    confirmDelete(id, userId, name) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `Are you sure you want to delete "${name}"?`,
        accept: () => this.deleteData(id, userId),
        acceptText: 'Delete',
      });
    },
    async deleteData(id, userId) {
      if (!this.ableToDeleteCampaign(userId)) {
        return;
      }

      this.$vs.loading();

      try {
        await this.removeCampaign(id);

        this.notifySuccess(
          'Campaign deleted',
          'The campaign has been successfully deleted.'
        );
      } catch (error) {
        this.notifyError(error, 'Problem with deleting a campaign.');
      }

      this.$vs.loading.close();
    },
    editData(data) {
      if (!this.ableToEditCampaign(data.user_id)) {
        return;
      }

      this.sidebarData = data;
      this.toggleDataSidebar(true);
    },
    openData({ id }) {
      if (!this.$gate.can('view_campaigns')) {
        return;
      }

      this.$router.push({ name: 'campaign-products', params: { id } }).catch();
    },
    resetDate(date) {
      this.filters[date] = null;
      this.filter();
    },
    createFilterQuery() {
      let query = '?';

      query += `region=${this.filters.region.value}&`;
      query += `country=${this.filters.country.value}&`;
      query += `status=${this.filters.status.value}&`;
      query += `start_date=${
        this.filters.startDate
          ? this.filters.startDate.getTime()
          : this.filters.startDate
      }&`;
      query += `end_date=${
        this.filters.endDate
          ? this.filters.endDate.getTime()
          : this.filters.endDate
      }&`;

      return query;
    },
    async filter() {
      this.$vs.loading();

      try {
        await this.fetchCampaigns(this.createFilterQuery());
      } catch (error) {
        this.notifyError(error, 'Problem with filtering campaigns.');
      }
      this.$vs.loading.close();
    },
  },
  async created() {
    this.registerStoreModules();

    this.$vs.loading();

    try {
      await Promise.all([this.fetchCampaigns(), this.fetchRetailers()]);
    } catch (error) {
      this.notifyError(error, 'Problem with loading data.');
    }

    this.$vs.loading.close();
  },
};
</script>

<style lang="scss">
.product-status {
  white-space: nowrap;
}

.datepicker-reset {
  position: absolute !important;
  top: 10px;
  right: 4px;
  cursor: pointer;
  z-index: 2;
}
</style>
