<template>
  <vs-sidebar
    v-model="isActive"
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="vs-sidebar__wrapper"
    spacer
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ titleType }} CAMPAIGN</h4>
      <feather-icon
        icon="XIcon"
        class="cursor-pointer"
        @click.stop="isActive = false"
      />
    </div>

    <vs-divider class="mb-0" />

    <component
      :is="scrollbarTag"
      :settings="settings"
      class="vs-sidebar__inner"
    >
      <!-- NAME -->
      <div>
        <vs-label text="Name" />
        <vs-input
          v-model="dataName"
          v-validate="'required'"
          class="w-full"
          name="name"
        />
        <v-error :err="errors" field="name" />
      </div>

      <!-- RETAILER -->
      <div class="mt-4">
        <vs-label text="Retailer" />
        <v-select
          v-model="dataRetailer"
          :options="retailerList"
          :clearable="false"
          name="retailer"
        />
      </div>

      <!-- DESCRIPTION -->
      <div class="mt-4">
        <vs-label text="Description" />
        <vs-textarea v-model="dataDescription" name="description" />
      </div>

      <!-- STATUS -->
      <div class="mt-4">
        <vs-label text="Status" />
        <v-select
          v-model="dataStatus"
          :options="campaignStatusList"
          :clearable="false"
          name="campaign-status"
        />
      </div>

      <!-- START DATE -->
      <div class="mt-4">
        <vs-label text="Start date" />
        <datepicker
          v-model="dataStartDate"
          placeholder="Select Date"
          class="w-full"
        />
      </div>

      <!-- END DATE -->
      <div class="mt-4">
        <vs-label text="End date" />
        <datepicker
          v-model="dataEndDate"
          placeholder="Select Date"
          class="w-full"
        />
      </div>

      <!-- UNIQUE ID -->
      <div class="mt-4">
        <vs-label text="Unique ID" />
        <vs-input
          v-model="dataUniqueId"
          v-validate="'max:254'"
          class="w-full"
          name="campaign-unique-id"
        />
      </div>

      <!-- BUTTONS -->
      <sidebar-buttons
        :valid="isFormValid"
        @click="handleSubmit"
        @cancel="handleClose"
      />
    </component>
  </vs-sidebar>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select';

import { CampaignAction } from '@/store/actionTypes';
import {
  CampaignStatusEnum,
  campaignStatusList,
  getStatusByValue,
} from '@/enums/CampaignStatusEnum';

import customErrorMixin from '@/mixins/customErrorMixin';
import sidebarMixin from '@/mixins/sidebarMixin';

import vError from '@/components/VError';

export default {
  components: {
    Datepicker,
    vError,
    vSelect,
  },
  mixins: [customErrorMixin, sidebarMixin],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      campaignStatusList,
      dataId: null,
      dataName: '',
      dataRetailer: null,
      dataDescription: '',
      dataStatus: CampaignStatusEnum.NEW,
      dataStartDate: new Date(),
      dataEndDate: null,
      dataUniqueId: null,
    };
  },
  watch: {
    active(val) {
      if (!val) {
        return;
      }

      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        const {
          id,
          name,
          description,
          status,
          start_date,
          end_date,
          retailer_id,
          unique_id,
        } = this.data;

        this.dataId = id;
        this.dataName = name;
        this.dataRetailer = this.retailerList.find(
          ({ value }) => value === retailer_id
        );
        this.dataDescription = description;
        this.dataStatus = getStatusByValue(status);
        this.dataStartDate = start_date;
        this.dataEndDate = end_date;
        this.dataUniqueId = unique_id;
        this.initValues();
      }
    },
  },
  computed: {
    ...mapState('retailer', {
      retailers: ({ retailers }) => retailers,
    }),

    retailerList() {
      return this.retailers.map(({ id, name }) => ({ value: id, label: name }));
    },
    isFormValid() {
      return !this.errors.any() && this.dataName;
    },
  },
  methods: {
    ...mapActions('campaign', [CampaignAction.add, CampaignAction.update]),

    initValues() {
      if (this.data.id) {
        return;
      }

      this.dataId = null;
      this.dataName = '';
      this.dataRetailer = null;
      this.dataDescription = '';
      this.dataStatus = CampaignStatusEnum.NEW;
      this.dataStartDate = new Date();
      this.dataEndDate = null;
      this.dataUniqueId = null;
    },
    async handleSubmit() {
      if (!(await this.$validator.validateAll())) {
        return;
      }

      const obj = {
        id: this.dataId,
        name: this.dataName,
        description: this.dataDescription,
        status: this.dataStatus.value,
        start_date: this.dataStartDate,
        end_date: this.dataEndDate,
        retailer_id: this.dataRetailer ? this.dataRetailer.value : null,
        unique_id: this.dataUniqueId,
      };

      this.$vs.loading();

      if (this.shouldUpdate) {
        try {
          await this.updateCampaign(obj);

          this.notifySuccess(
            'Campaign updated',
            'The campaign has been successfully updated'
          );

          this.$emit('close');
          this.initValues();
        } catch (error) {
          this.handleCustomError(error, 'Problem with updating a campaign.');
        }
      } else {
        delete obj.id;

        try {
          await this.addCampaign(obj);

          this.notifySuccess(
            'Campaign added',
            'The campaign has been successfully added.'
          );

          this.$emit('close');
          this.initValues();
        } catch (error) {
          this.handleCustomError(error, 'Problem with adding a campaign.');
        }
      }

      this.$vs.loading.close();
    },
  },
};
</script>
